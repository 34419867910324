export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: undefined,
    },
    emptySubMessage: {
      type: String,
      default: undefined,
    },
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 4,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
      currentItemsPerPage: 4,
    };
  },

  computed: {
    numberOfElements: {
      get() {
        return this.$store.state.userSettings.numberOfElements;
      },
      set(value) {
        this.$store.commit('userSettings/numberOfElements', value);
      },
    },
  },
  created() {
    if (this.page) {
      this.currentPage = this.page;
    }
    if (this.itemsPerPage !== this.numberOfElements) {
      this.currentItemsPerPage = this.numberOfElements;
    }
  },

  watch: {
    page(value) {
      this.currentPage = value;
    },
    currentPage(value) {
      this.$emit('update:page', value);
    },

    itemsPerPage(value) {
      this.currentItemsPerPage = value;
    },

    currentItemsPerPage(value) {
      this.numberOfElements = value;
      this.$emit('update:itemsPerPage', value);
    },
  },

  methods: {
    updateItemsPerPage(number) {
      this.currentItemsPerPage = number;
    },
    updatePage(number) {
      this.currentPage = number;
    },
  },
};
