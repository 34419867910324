<template>
  <v-row class="mt-2 mx-1"
         align="center"
         justify="center">
    <v-col class="py-0">
      <span class="grey--text">Items per page</span>
      <v-menu offset-y>
        <template #activator="{on}">
          <v-btn dark
                 text
                 color="warning"
                 class="ml-1 px-0"
                 v-on="on">
            {{ itemsPerPage }}
            <v-icon color="warning">
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(number, index) in itemsPerPageArray"
                       :key="index"
                       @click="updateItemsPerPage(number)">
            <v-list-item-title>{{ number }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col class="py-0">
      <v-pagination v-model="currentPage"
                    :length="totalPages"
                    :total-visible="14"
                    color="warning"
                    class="my-4 float-right" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 4,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      currentPage: this.page,
    };
  },
  watch: {
    currentPage(value) {
      this.$emit('update-page', value);
    },
  },

  methods: {
    updateItemsPerPage(number) {
      this.$emit('update-items-per-page', number);
    },
  },
};
</script>
